#younetLogoImg {
 filter: brightness(0) invert(1);
 width: 123px;
 max-height: 40px;
 min-height: 40px;
}

#younetLogoImg.logo-mode-compact {
 width: 30px;
}

#younetLogoImg.logo-mode-corner {
 position: absolute;
 top: 10px;
 left: 20px;
}

